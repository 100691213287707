<template>
  <b-card class="page-card" headerClass="page-card-header" bodyClass="page-card-body">
    <div slot="header">
      <div class="d-flex align-self-center">
        <div class="pr-1 d-flex align-self-center">
          <i :class="iconClass || 'd-none'"></i>
        </div>
        <div>
          <h5 class="mb-0">
            <b>{{ pageName }}</b>
            <span v-if="pageType">
              - <i>{{ pageType }}</i></span
            >
          </h5>
        </div>
      </div>
    </div>

    <slot></slot>
  </b-card>
</template>

<script>
export default {
  name: 'BasicPageCard',
  props: {
    pageName: {
      type: String,
      required: true,
      default: '-'
    },
    pageType: {
      type: String,
      required: false
    },
    iconClass: {
      type: String,
      required: false
    }
  }
}
</script>
